import "lazysizes";
import "picturefill";
// var $ = require('jQuery');

//アコーディオン
$(".topFaq__item__q").each(function () {
  $(this).on("click", function () {
    $("+.topFaq__item__a", this).slideToggle();
    $(this).toggleClass("is-active");
    return false;
  });
});

//ヘッダーメニュー
$(".header__spBtn, .header__spMenu__btn").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($(".header__spBtn").hasClass("is-active")) {
    $(".header__spBtn").removeClass("is-active");
    $(".header__spMenu").slideUp();
  } else {
    $(".header__spBtn").addClass("is-active");
    $(".header__spMenu").slideDown();
  }
});

//セレクトボックスが切り替わったら発動
$("#selectType").change(function () {
  //選択したvalue値を変数に格納
  var val = $(this).val();
  console.log({ val });
  if (val !== "") {
    $(".topContact__form__accordion").slideDown();
  } else {
    $(".topContact__form__accordion").slideUp();
  }
});

$(function () {
  var val = $("#selectType").val();
  if (val !== "") {
    $(".topContact__form__accordion").slideDown();
  } else {
    $(".topContact__form__accordion").slideUp();
  }

  if ($(".error")[0]) {
    $(".mw_wp_form").addClass("mw_wp_form_error");
    var errorEl = $(".mw_wp_form").eq(0);
    var position = errorEl.parent().offset().top - 100;
    $("body,html").delay(200).animate({ scrollTop: position }, 0, "swing");
  }
});
